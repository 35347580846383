.kitchen {
  margin-top: auto;
  margin-bottom: 20px;
  padding: 0;
  position: relative;
  width: 100%;
  animation: slide-in 0.8s;
  display: block;
  border-radius: 20px;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.808);
  border-radius: 20px;
}

.border-obj {
  border: 3px solid white;
  border-radius: 5px;
}

.object-container {
  margin-top: 10px;
  padding: 20px;
  border-radius: 20px;
  background: #ffda24;
  border: 4px solid white;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.808);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  min-width: 450px;
  margin-bottom: 30px;
}

.objects {
  padding: 2px;
  width: 50px;
  height: 50px;
}

.image-container {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

#hamburger {
  object-fit: cover;
  max-width: 50px;
  position: absolute;
  top: 71%;
  left: 91%;
}

#mixing {
  object-fit: contain;
  max-width: 70px;
  position: absolute;
  max-height: 100px;
  top: 65%;
  left: 46%;
}

#groceries {
  object-fit: contain;
  max-width: 80px;
  position: absolute;
  max-height: 100px;
  top: 42%;
  left: 47%;
}

#chicken {
  object-fit: contain;
  max-width: 100px;
  position: absolute;
  max-height: 250px;
  top: 60%;
  left: 18%;
}

#dabba {
  object-fit: contain;
  max-width: 270px;
  position: absolute;
  top: 65%;
  left: 50%;
}

#mixer {
  object-fit: contain;
  max-width: 100px;
  position: absolute;
  max-height: 100px;
  top: 60%;
  left: 13%;
}

#kitchenware {
  object-fit: contain;
  max-width: 200px;
  position: absolute;
  top: 60%;
  left: 88%;
}

#knife {
  object-fit: contain;
  max-width: 60px;
  position: absolute;
  max-height: 100px;
  top: 65%;
  left: 33%;
}

#tool {
  object-fit: contain;
  max-width: 50px;
  position: absolute;
  max-height: 100px;
  top: 65%;
  left: 40%;
}

.image-container {
  max-width: 900px;
  border-radius: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.small-image {
  position: absolute;
  padding: 2px;
  border-radius: 10px;
  height: auto;
  animation: fadeIn 3s;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@media (max-width: 1024px) {
  .image-container {
    max-width: 800px;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  #hamburger {
    max-width: 50px;
    max-height: 50px;
    top: 69%;
    left: 92%;
  }

  #mixing {
    max-width: 70px;
    max-height: 100px;
    top: 62%;
    left: 46%;
  }

  #groceries {
    max-width: 50px;
    max-height: 100px;
    top: 46%;
    left: 48%;
  }

  #chicken {
    max-width: 70px;
    max-height: 150px;
    top: 60%;
    left: 18%;
  }

  #dabba {
    max-width: 150px;
    max-height: 80px;
    top: 62%;
    left: 50%;
  }

  #mixer {
    max-width: 80px;
    max-height: 100px;
    top: 58%;
    left: 13%;
  }

  #kitchenware {
    max-width: 200px;
    top: 58%;
    left: 88%;
  }

  #knife {
    max-width: 70px;
    max-height: 100px;
    top: 63%;
    left: 30%;
  }

  #tool {
    max-width: 50px;
    max-height: 100px;
    top: 65%;
    left: 40%;
  }

  @media (max-width: 880px) {
    .image-container {
      max-width: 700px;
      margin-top: 20px;
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .object-container {
      min-width: 400px;
    }

    #hamburger {
      max-width: 50px;
      max-height: 40px;
      top: 70%;
      left: 92%;
    }

    #mixing {
      max-width: 70px;
      max-height: 100px;
      top: 59.5%;
      left: 46%;
    }

    #groceries {
      max-width: 50px;
      max-height: 100px;
      top: 44.3%;
      left: 48%;
    }

    #chicken {
      max-width: 70px;
      max-height: 150px;
      top: 60%;
      left: 18%;
    }

    #dabba {
      max-width: 150px;
      max-height: 80px;
      top: 61%;
      left: 50%;
    }

    #mixer {
      max-width: 80px;
      max-height: 100px;
      top: 60%;
      left: 13%;
    }

    #kitchenware {
      max-width: 80px;
      max-height: 80px;
      top: 60%;
      left: 88%;
    }

    #knife {
      max-width: 50px;
      max-height: 100px;
      top: 62%;
      left: 28%;
    }

    #tool {
      max-width: 50px;
      max-height: 100px;
      top: 65%;
      left: 38%;
    }
  }

  @media (max-width: 780px) {
    .image-container {
      max-width: 550px;
      margin-top: 20px;
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    #hamburger {
      max-width: 30px;
      max-height: 40px;
      top: 68%;
      left: 92%;
    }

    #mixing {
      max-width: 50px;
      max-height: 100px;
      top: 63%;
      left: 46%;
    }

    #groceries {
      max-width: 50px;
      max-height: 100px;
      top: 41.5%;
      left: 46%;
    }

    #chicken {
      max-width: 70px;
      max-height: 150px;
      top: 55%;
      left: 16%;
    }

    #dabba {
      max-width: 70px;
      max-height: 80px;
      top: 58%;
      left: 50%;
    }

    #mixer {
      max-width: 80px;
      max-height: 100px;
      top: 54%;
      left: 10%;
    }

    #kitchenware {
      max-width: 70px;
      top: 55%;
      left: 87%;
    }

    #knife {
      max-width: 45px;
      max-height: 100px;
      top: 63%;
      left: 28%;
    }

    #tool {
      max-width: 40px;
      max-height: 100px;
      top: 62%;
      left: 37%;
    }
  }

  @media (max-width: 620px) {
    .image-container {
      max-width: 400px;
      margin-top: 20px;
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    #logo {
      margin-top: 0px;
    }

    .object-container {
      margin-top: 40px;
      min-width: 250px;
    }

    .objects {
      width: 50px;
      height: 50px;
      padding: 10px;
    }

    #hamburger {
      max-width: 20px;
      max-height: 40px;
      top: 68%;
      left: 92%;
    }

    #mixing {
      max-width: 30px;
      max-height: 100px;
      top: 63%;
      left: 46%;
    }

    #groceries {
      max-width: 30px;
      max-height: 100px;
      top: 43%;
      left: 48%;
    }

    #chicken {
      max-width: 40px;
      max-height: 150px;
      top: 60%;
      left: 16%;
    }

    #dabba {
      max-width: 50px;
      max-height: 80px;
      top: 57%;
      left: 50%;
    }

    #mixer {
      max-height: 50px;
      top: 57%;
      left: 11%;
    }

    #kitchenware {
      max-width: 40px;
      top: 58%;
      left: 88%;
    }

    #knife {
      max-width: 30px;
      max-height: 100px;
      top: 63%;
      left: 30%;
    }

    #tool {
      max-width: 30px;
      max-height: 100px;
      top: 63%;
      left: 38%;
    }
  }

  @media (max-width: 420px) {
    .kitchen {
      margin-top: auto;
      margin-bottom: 20px;
      padding: 0;
      height: 200px;
      width: 100%;
      animation: slide-in 0.8s;
      display: block;
      border-radius: 20px;
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.808);
      border-radius: 20px;
    }

    .objects {
      width: 40px;
      height: 40px;
      padding: 5px;
    }

    .game-container {
      margin-top: 15vh;
    }

    .object-container {
      min-width: 220px;
    }

    .image-container {
      max-width: 300px;
      margin-top: 20px;
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    #hamburger {
      max-width: 25px;
      max-height: 45px;
      top: 66%;
      left: 90%;
    }

    #mixing {
      max-width: 30px;
      max-height: 100px;
      top: 60%;
      left: 49%;
    }

    #groceries {
      max-width: 25px;
      max-height: 100px;
      top: 43%;
      left: 47%;
    }

    #chicken {
      max-width: 50px;
      max-height: 190px;
      top: 54%;
      left: 12%;
    }

    #dabba {
      max-width: 30px;
      max-height: 80px;
      top: 61.5%;
      left: 54%;
    }

    #mixer {
      max-width: 50px;
      max-height: 100px;
      top: 53%;
      left: 8%;
    }

    #kitchenware {
      max-width: 40px;
      top: 53%;
      left: 86%;
    }

    #knife {
      max-width: 30px;
      max-height: 100px;
      top: 62%;
      left: 28%;
    }

    #tool {
      max-width: 25px;
      max-height: 100px;
      top: 60%;
      left: 38%;
    }
  }
}
